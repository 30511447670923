import { useTranslation } from 'react-i18next';

export default function Footer() {
    const {t} = useTranslation();

    return (
        // <footer class="w-full max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <footer className="flex justify-center sm:p-4 lg:p-8">
            <div className="w-2/3 py-6 border-t border-gray-200">
                <div className="flex flex-wrap justify-between items-center gap-2">
                    <div>
                        <p className="text-xs text-gray-600">{t('devby')}</p>
                    </div>
                    <ul className="flex flex-wrap items-center">
                        <li className="inline-block relative pe-4 text-xs last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-1.5 before:-translate-y-1/2 before:size-[3px] before:rounded-full before:bg-gray-400">
                            <a className="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2 focus:outline-none focus:decoration-2" href="/">
                                {t('about')}
                            </a>
                        </li>
                        <li className="inline-block relative pe-4 text-xs last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-1.5 before:-translate-y-1/2 before:size-[3px] before:rounded-full before:bg-gray-400">
                            <a className="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2 focus:outline-none focus:decoration-2" href="/">
                                {t('contact')}
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </footer>
    )
}