import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {promptDefault1, promptDefault2, promptStep1, promptStep2, exampleInput} from "./components/Prompt";

function App() {
	const {t} = useTranslation();
	const [output, setOutput] = useState(t("wati"));
	const [step1Output, setStep1Output] = useState("");
  	const [isLoading, setIsLoading] = useState(false);
	const [err, setErr] = useState("");
	const [isCopied, setIsCopied] = useState(false);
	const [input, setInput] = useState(exampleInput)
	const [outputAreaHeight, setOutputAreaHeight] = useState(1)

	const [searchParams] = useSearchParams()
	const [apikey, setApikey] = useState("");

	// setTimeout(()=>setOutput(t("wati")),1000)

	useEffect(() => {
		fetch('/config/key.json')
      		.then(response => {
				if (!response.ok) {
					const {apikey} = Object.fromEntries(searchParams)
					setApikey(apikey)
				}
				return response.json();
			})
			.then(data => {
				setApikey(data.apikey)
				if(data.apikey == ""){
					const {apikey} = Object.fromEntries(searchParams)
					setApikey(apikey)
				}
			})
      		.catch(error => {
				const {apikey} = Object.fromEntries(searchParams)
				setApikey(apikey)
			});
	}, []);

	const submitTask = async () => {
		// const {apikey} = Object.fromEntries(searchParams)
		// console.log(apikey)
		setIsLoading(true);
		setIsCopied(false);
		setOutput(t("tp"))
		try {
			const response = await fetch('https://api.deepseek.com/chat/completions', {
				method: 'POST',
				body: JSON.stringify({
					"messages": [
					  	{"role": "system", "content": promptDefault1},
					  	{"role": "user", "content": promptStep1},
            			{"role": "user", "content": "title and abstract: "+input}
					],
					"model": "deepseek-coder",
					"frequency_penalty": 0,
					"max_tokens": 2048, 
					"presence_penalty": 0,
					"stop": null,
					"stream": false,
					"temperature": 1,
					"top_p": 1,
					"logprobs": false,
					"top_logprobs": null
				}),
				headers: {
					'Content-Type': 'application/json',
    				'Accept': 'application/json', 
    				'Authorization': `Bearer ${apikey}`
				},
			});

			if (!response.ok) {
				throw new Error(`Error! status: ${response.status}`);
			}

			const result = await response.json();
			// console.log(result)
			const resultMsg = result.choices[0].message.content.replaceAll("```","").replace("json","")
			// setOutput(JSON.stringify(JSON.parse(resultMsg), null, 4))
			setStep1Output(JSON.parse(resultMsg))
			executeStep2()
			// console.log('result is: ', JSON.stringify(result, null, 4));
		} catch (err) {
			setErr(err.message);
			setOutput(t("tperr"));
			setIsLoading(false);
		} finally {
			// setIsLoading(false);
		}
	};

	const executeStep2 = async () =>{
		try {
			const response = await fetch('https://api.deepseek.com/chat/completions', {
				method: 'POST',
				body: JSON.stringify({
					"messages": [
					  	{"role": "system", "content": promptDefault2},
					  	{"role": "user", "content": promptStep2},
						{"role": "user", "content": "extracted entities: "+step1Output},
						{"role": "user", "content": "title and abstract: "+input}
					],
					"model": "deepseek-coder",
					"frequency_penalty": 0,
					"max_tokens": 2048, 
					"presence_penalty": 0,
					"stop": null,
					"stream": false,
					"temperature": 1,
					"top_p": 1,
					"logprobs": false,
					"top_logprobs": null
				}),
				headers: {
					'Content-Type': 'application/json',
    				'Accept': 'application/json', 
    				'Authorization': `Bearer ${apikey}`
				},
			});

			if (!response.ok) {
				throw new Error(`Error! status: ${response.status}`);
			}

			const result = await response.json();
			// console.log(result)
			const resultMsg = result.choices[0].message.content.replaceAll("```","").replace("json","")
			setOutput(JSON.stringify(JSON.parse(resultMsg), null, 4))
			// console.log(JSON.parse(resultMsg).length)
			// console.log('result is: ', JSON.stringify(result, null, 4));
		} catch (err) {
			setErr(err.message);
			setOutput(t("tperr"));
		} finally {
			setIsLoading(false);
		}
	}

  	return (
    	<div className="App bg-slate-100">
			<Header></Header>

			<section className="flex items-center justify-center sm:p-4 lg:p-8">
				<div className="w-2/3 flex justify-between flex-wrap">
					<div className="rounded-lg bg-gray-50 p-6 my-2 font-medium text-gray-900">
						{t('intro')}
					</div>
					<div className="flex justify-between my-2">
						<div className="rounded-lg bg-gray-50 p-6 mr-2 font-medium text-gray-900 justify-self-end">
							<span className="text-gray-800">{t('uc')}</span> <span className="text-sky-500 font-medium">386</span>
						</div>
						<div className="rounded-lg bg-gray-50 p-6 font-medium text-gray-900">
							<span className="text-gray-800">{t('rc')}</span> <span className="text-indigo-600 font-medium">6384</span>
						</div>
					</div>
				</div>
			</section>

			<section className="section-input flex items-center justify-center sm:p-4 lg:p-8">
				<div className="w-2/3">
					<label for="inputArea" className="block mb-2 font-medium text-gray-900">{t('ati')}</label>
					<textarea id="inputArea" value={input} onChange={e => setInput(e.target.value)} rows="4" className="block p-2.5 w-full h-64 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder={t('pea')}></textarea>
					<div className="flex items-center justify-end gap-2 py-3">
						{!isLoading && <button type="button" onClick={submitTask} className="rounded bg-indigo-600 px-3 py-1.5 text-sm font-medium text-white hover:bg-indigo-700">{t('st')}</button>}
						{isLoading && <button className="inline-flex rounded bg-indigo-500 px-3 py-1.5 text-sm font-medium text-white hover:bg-indigo-500" disabled>
							<span>
								<svg className="animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle opacity="0.5" cx="10" cy="10" r="9" stroke="white" stroke-width="2"/>
									<mask id="path-2-inside-1_2527_20936" fill="white">
									<path d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z" />
									</mask>
									<path
									d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z"
									stroke="white" stroke-width="4" mask="url(#path-2-inside-1_2527_20936)"
									/>
								</svg>
							</span>
							{t('working')}
						</button>}
					</div>
				</div>
			</section>

			<section className="section-output flex items-center justify-center sm:p-4 lg:p-8">
				<div className="w-2/3">
					<label for="outputArea" className="block mb-2 font-medium text-gray-900">{t('ero')}</label>
					<div id="outputArea" className="bg-gray-900 text-white p-4 rounded-lg" className={`bg-gray-900 text-white p-4 rounded-lg h-[${outputAreaHeight*320}]px`}>
						<div className="flex justify-between items-center mb-2">
							<span className="text-gray-400 text-sm">JSON</span>

							<button onClick={() => {navigator.clipboard.writeText(output); setIsCopied(true)}} className="text-gray-300 m-0.5 hover:bg-gray-700 rounded-md py-2 px-2.5 inline-flex items-center justify-center bg-gray-800">
								<span id="default-message" class="inline-flex items-center">
									<svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
										<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
									</svg>
									{!isCopied && <span class="text-xs font-semibold">{t('copy1')}</span>}
									{isCopied && <span class="text-xs font-semibold">{t('copy2')}</span>}
								</span>
        					</button>
						</div>
						<div className="overflow-x-auto">
							<pre id="code" className="text-gray-300 text-sm">
								<code>{output}</code>
							</pre>
						</div>
					</div>
				</div>
			</section>
			
			<Footer></Footer>
    	</div>
  	);
}

export default App;
