import { useState } from 'react'
import {
  Dialog,
  DialogPanel,
  PopoverGroup,
} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import LangToggle from "./LangToggle";
import { useTranslation } from 'react-i18next';


export default function Header() {
    const {t, i18n} = useTranslation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
        <header className="bg-white">
            <nav aria-label="Global" className="mx-auto flex max-w-8xl items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1 items-center">
                        {i18n.language == "en" 
                            ? <img src="/img/logo_en.png" width="230"></img>
                            : <img src="/img/logo_zh.png" width="230"></img>
                        }
                        <a className="text-2xl mr-2.5 mb-1"> | </a>
                        <a href="#" className="text-lg font-semibold leading-6 text-gray-900 mr-3">{t('appname')}</a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">{t('index')}</a>
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">{t('app')}</a>
                </PopoverGroup>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center">
                    <LangToggle></LangToggle>
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        {t('login')} <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                        <a href="#" className="text font-semibold leading-6 text-gray-900">{t('appname')}</a>
                    </a>
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                        <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{t('index')}</a>
                        <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{t('app')}</a>
                    </div>
                    <div className="py-6">
                        <LangToggle></LangToggle>
                        <a href="#" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{t('login')}</a>
                    </div>
                    </div>
                </div>
                </DialogPanel>
            </Dialog>
    </header>
  )
}
