import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "appname": "Biomedical Research Result Extraction Tool",
            "index": "Home page",
            "app": "Application",
            "login": "login",
            "intro": "This tool is used to extract PICO, primary results, and study design from biomedical research abstracts.",
            "uc": "User count",
            "rc": "Run count",
            "ati": "Abstract text input",
            "pea": "Please enter the abstract (Character limit: 1000, English)",
            "st": "Submit",
            "ero": "Extracted results output",
            "copy1": "Copy",
            "copy2": "Copied",
            "wati": "Waiting for abstract text input",
            "devby": "© Developed by: National Institute of Health Data Science at Peking University",
            "about": "About",
            "contact": "Contact us",
            "tp": "Working on task",
            "tperr": "Task processing error",
            "working": "Working"
        }
    },
    zh: {
        translation: {
            "appname": "医学研究结果抽取工具",
            "index": "首页",
            "app": "应用",
            "login": "登录",
            "intro": "本工具用于抽取医学研究摘要中PICO信息、主要结果及设计信息",
            "uc": "用户量",
            "rc": "运行次数",
            "ati": "摘要文本输入",
            "pea": "请输入摘要（字数上限：1000字，英文）",
            "st": "提交任务",
            "ero": "抽取结果输出",
            "copy1": "复制代码",
            "copy2": "已复制",
            "wati": "等待摘要文本输入",
            "devby": "© 研发单位：北京大学健康医疗大数据国家研究院",
            "about": "关于",
            "contact": "联系我们",
            "tp": "任务处理中",
            "tperr": "任务处理失败",
            "working": "处理中"
        }
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;