import { useState } from 'react'
import { useTranslation } from 'react-i18next';

const LangToggle = () => {
  const {i18n} = useTranslation();

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <label className='relative inline-flex cursor-pointer select-none items-center mr-2'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          className='sr-only'
        />
        <div className='shadow-card flex h-[46px] w-[82px] items-center justify-center rounded-md bg-white'>
          <span onClick={()=>i18n.changeLanguage("zh")}
            className={`flex h-8 w-8 items-center font-semibold justify-center rounded ${
              isChecked ? 'bg-indigo-600 text-white' : 'text-gray-900'
            }`}
          >中</span>
          <span onClick={()=>i18n.changeLanguage("en")}
            className={`flex h-8 w-8 text-sm font-semibold items-center justify-center rounded ${
              !isChecked ? 'bg-indigo-600 text-white' : 'text-gray-900'
            }`}
          >Eng</span>
        </div>
      </label>
    </>
  )
}

export default LangToggle